




import { Vue, Component } from "vue-property-decorator";
import CommunicationTemplates from "@/views/Communication/CommunicationTemplates.vue";

@Component({
  components: { CommunicationTemplates },
})
export default class MessageTemplates extends Vue {
  public name = "MessageTemplates";
}
